<template>
   <v-dialog :value="show" width="700" persistent>
      <v-card>
         <v-card-title class="headline">
            Pembayaran
         </v-card-title>
         <v-card-text>
            <v-form>
               <div class="title blue--text">Pembeli</div>
               <v-row dense justify="space-between">
                  <v-col cols="12" sm="6">
                     <v-combobox
                        v-model="customer"
                        :items="customers"
                        label="Nama Pelanggan"
                        :loading="load_customer"
                        item-value="id"
                        item-text="name"
                        :search-input.sync="search_customer"
                     >
                        <template v-slot:item="{item}">
                           <v-list-item-content>
                              <v-list-item-title v-text="item.name"/>
                              <v-list-item-subtitle v-if="item.phone || item.address"><span v-text="item.phone"/><span v-text="item.address"/></v-list-item-subtitle>
                              <v-divider class="mx-6"/>
                           </v-list-item-content>
                        </template>
                     </v-combobox>
                     <!-- <v-text-field v-model="fm.name" label="Nama" hint="nama pembeli"/> -->
                  </v-col>
                  <v-col cols="12" sm="5">
                     <v-text-field :disabled="excisting_customer" v-model="fm.phone" label="Handphone" hint="masukan no hp pelanggan" />
                  </v-col>
                  <v-col cols="12">
                     <v-text-field :disabled="excisting_customer" v-model="fm.address" label="Alamat" hint="masukan alamat pelanggan" />
                  </v-col>
               </v-row>
            </v-form>
            <!-- <v-divider /> -->
            <div class="title blue--text">Daftar Belanja</div>
         </v-card-text>
         <v-simple-table dense>
            <template v-slot:default>
               <thead>
                  <tr>
                     <th class="text-right">No</th>
                     <th>Barang</th>
                     <th class="text-right">Harga</th>
                     <th class="text-center">Quantity</th>
                     <th class="text-right">Jumlah</th>
                     <th class="text-center">Pilih</th>
                  </tr>
               </thead>
               <tbody>
                  <tr v-for="(r,i) in fm.product" :key="i">
                     <td class="text-right">{{i + 1}}</td>
                     <td>{{r.name}}</td>
                     <td class="text-right" v-idr="r.price"/>
                     <td class="text-center">
                        <div class="qty-adj">
                           <v-icon @click="sub(r)" color="blue lighten-1">mdi-minus-circle</v-icon>
                           <div><v-text-field v-model="r.quantity"/></div>
                           <v-icon @click="add(r)" color="blue lighten-1">mdi-plus-circle</v-icon>
                           <!-- <v-icon class="ml-1" @click="del(i)" color="red lighten-1">mdi-delete-outline</v-icon> -->
                        </div>
                     </td>
                     <td class="text-right" v-idr="r.price* r.quantity"/>
                     <td class="text-center"><v-checkbox v-model="r.selected" /></td>
                  </tr>
                  <tr class="text-h6">
                     <td colspan="3" class="text-right">Total</td>
                     <td class="text-center">{{sum_take}}</td>
                     <td class="text-right" v-idr:full="total"/>
                     <td class="text-center"><v-checkbox v-model="check_all" @change="selectAll" /></td>
                  </tr>
               </tbody>
            </template>
         </v-simple-table>
         <v-card-text>
            <v-row dense>
               <v-col cols="8" class="text-right pr-6 pt-4">Diskon</v-col>
               <v-col cols="4">
                  <input-currency class="inp-right" v-model="fm.discount" hide-details dense/>
               </v-col>
               <v-col cols="8" class="text-right pr-6 pt-4">Bayar</v-col>
               <v-col cols="4">
                  <input-currency class="inp-right" v-model="fm.cash" hide-details dense/>
                  <!-- <v-text-field class="inp-right" v-model="fm.cash" hide-details dense/> -->
               </v-col>
               <v-col cols="8" class="text-right pr-6 pt-4">Kembalian</v-col>
               <v-col cols="4" class="text-right">
                  <input-currency class="inp-right" v-model="cash_change" hide-details dense/>
               </v-col>
               <v-col cols="12">
                  <v-text-field label="Catatan" v-model="fm.note" />
               </v-col>
            </v-row>
         </v-card-text>
         <!-- <v-divider /> -->
         <v-card-actions class="py-4">
            <v-btn color="secondary" width="120" outlined rounded @click="close">batal</v-btn>
            <v-spacer/>
            <v-btn color="orange" :loading="load_save" width="120" outlined rounded @click="save(2)">simpan</v-btn>
            <v-btn color="primary" :loading="load_save" width="220" outlined rounded @click="submit">proses</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import api from '../api'
import inputCurrency from './inputCurrency.vue';
export default {
  components: { inputCurrency },
   name: 'Transaction',
   computed: {
      show() {
         return !!this.$store.state.transaction.id;
      },
      product() {
         if(!this.fm.product) return [];
         return this.fm.product.filter(x => x.selected);
      },
      sum_take() {
         return this.product.reduce((a, b) => a + parseInt(b.quantity), 0);
      },
      cash_change: {
         get() {
            const ch = this.fm.cash - (this.total - this.fm.discount);
            this.$set(this.fm, 'cash_change', ch < 0 ? 0 : ch);
            return ch < 0 ? 0 : ch;
         },
         set(val) {
            this.$set(this.fm, 'cash_change', val);
         }
      },
      total() {
         return this.product.reduce((a, b) => a + (parseInt(b.price) * parseInt(b.quantity)), 0);
      },
   },
   data: () => ({
      check_all: true,
      fm: {cash: 0, discount: 0, cash_change: 0},
      load_save: false,
      excisting_customer: false,
      customer: null,
      customers: [],
      search_customer: null,
      load_customer: false,
   }),
   watch: {
      search_customer(val) {
         clearTimeout(this.search_cust);
         this.load_customer = true;
         this.search_cust = setTimeout(() => {
            api.get(`customer?name=${val}`).then(rsl => {
               if(rsl.data) this.customers = rsl.data;
            }).finally(() => { this.load_customer = false })
         }, 800)
      },
      show(val) {
         if(val) this.fm = {...this.fm, ...this.$store.state.transaction};
         else {
            this.fm = {cash: 0, discount: 0, cash_change: 0};
            this.customer = null;
         }
         this.load_save = false;
      },
      customer(val) {
         if(val && val.id) {
            this.excisting_customer = true;
            this.fm = {...this.fm,customer_id:val.id,phone:val.phone,address: val.address};
         } else {
            this.excisting_customer = false;
            this.fm = {...this.fm,customer_id:null,name:val,phone:null,address:null};
         }
      }
   },
   methods: {
      selectAll(val) {
         this.$store.commit('selectAll', val);
      },
      add(p) {
         this.$store.commit('updateCart', {...p, take: ++p.quantity});
      },
      sub(p) {
         if(p.quantity < 1) return;
         this.$store.commit('updateCart', {...p, take: --p.quantity});
      },
      del(i) {
         this.$store.commit('delCart', i);
      },
      close() {
         this.$store.commit('set', {transaction: {}});
      },
      save(status) {
         if(!this.product.length) {
            return this.$alert('Ooops!', 'Tidak ada produk yang diplih', 'error');
         }
         this.load_save = true;
         if(status === 2) this.fm.status = 2;
         // console.log('save', this.fm);
         api.create('transaction', {...this.fm, product: this.product, total: this.total}).then(rsl => {
            if(rsl.status !== 1) return this.$alert('Ooops!', rsl.msg, 'error');
            this.$store.commit('set', {transaction: {}, cart: []});
            // window.open(`receipt/${rsl.id}`);
            this.$alert({ title: 'Success', text: rsl.msg, type: 'success', timer: 2000, ok: () => this.close });
         }).finally(() => { this.load_save = false });
      },
      submit() {
         if(this.fm.cash < this.total) {
            this.$alert({
               title: 'Perhatian!',
               text: 'Pembayaran belum sesuai, lanjutkan?',
               type: 'warning',
               ok: this.save,
               cancel: true,
            });
         } else this.save();
      }
   },
}
</script>
<style scoped>
.qty-adj {min-width: 90px;}
.qty-adj i.v-icon {cursor: pointer;font-size: 1.3em;}
.qty-adj div {width: 40px; display: inline-block;}
.qty-adj div >>> input {text-align: center;}
.inp-right >>> input {text-align: right;}
.v-list-item__subtitle > span + span::before {content: ' - ';}
</style>