import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import alert from './plugins/nalert'
import myDirective from './plugins/my-directive'

Vue.config.productionTip = false
Vue.use(alert);
Vue.use(myDirective);

new Vue({
   vuetify,
   store,
   router,
   render: h => h(App)
}).$mount('#app')
