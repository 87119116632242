export default {
   api_url: process.env.VUE_APP_API_URL || '/api',
   img_url: process.env.VUE_APP_IMG_URL || '/image/',
   tokenKey: 'pos-token',
   tokenHeaderKey: 'x-access-token',
   site_name: 'Toko Ratim S',
   base_url: '/app',
   avatar_width: 150,
   avatar_height: 150,
}
