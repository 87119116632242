<template>
   <v-dialog :value="!!uid" persistent max-width="600" >
      <v-card :loading="loading">
         <v-card-title>
            User {{uid === 'new'?'Baru':'Profile'}}
         </v-card-title>
         <v-card-text>
            <v-form class="row justify-space-between mx-0" ref="form">
               <v-col cols="12" md="6">
                  <!-- <div class="title mb-4">Profile</div> -->
                  <v-text-field label="Nama Lengkap" v-model="usr.fullname" :rules="[v => !!v || 'Nama lengkap harus diisi']"/>
                  <v-text-field :disabled="uid !== 'new'" label="username" v-model="usr.username" :rules="[v => !!v || 'Username diperlukan']" />
                  <v-select label="Group" v-model="usr.group_id" :items="groups" />
                  <v-switch v-if="uid !== 'new'" v-model="usr.disabled" label="Nonaktif" primary />
                  <v-switch v-if="uid !== 'new'" v-model="ch_passwd" label="Ubah password" warning />
                  <template v-if="ch_passwd || uid ==='new'">
                     <v-text-field v-if="uid === 'profile'"  label="Password Lama" v-model="usr.password0" />
                     <v-text-field type="password" label="Password" v-model="usr.password" :rules="[v => !!v || 'Password harus diisi', v => !!v && (v.length > 4) || 'Password minimal 5 karakter']"/>
                     <v-text-field type="password" label="Password Konfirmasi" v-model="usr.password2" :rules="[checkPasswd]"/>
                  </template>
               </v-col>
               <v-col cols="12" md="5">
                  <div class="title mb-4">Avatar</div>
                  <input-img :url="avatar" v-model="usr.base64_image" :maxHeight="200" :maxWidth="200" />
               </v-col>
            </v-form>
         </v-card-text>
         <v-card-actions>
            <v-spacer/>
            <v-btn width="120" outlined rounded color="secondary" @click="$emit('close')">tutup</v-btn>
            <v-btn width="180" :loading="load_save" outlined rounded color="primary" @click="save">simpan</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>
<script>
import config from '../config';
import api from '../api';
import inputImg from '../components/inputImg.vue';

export default {
   props: ['uid'],
   components: { inputImg },
   data: () => ({
      usr: {},
      pwd: {},
      ch_passwd: false,
      files: [],
      groups: [],
      loading: false,
      load_save: false,
   }),
   computed: {
      avatar() {
         return this.usr.avatar && config.img_url + this.usr.avatar;
      }
   },
   mounted () {
      api.get('user/group').then((rsl) => {
         if(rsl.data) {
            this.groups = rsl.data.map(x => ({value: x.id, text: x.name}));
         }
      })
   },
   watch: {
      uid(val) {
         if(val) {
            if(val === 'new') {
               this.usr = {id: 'new'};
            } else {
               this.loading = true;
               api.get(`user/${val}`).then((rsl) => {
                  if(rsl.data) {
                     this.usr = rsl.data;
                  }
               }).finally(() => this.loading = false)
            }
         } else this.$refs.form.reset();
      }
   },
   methods: {
      checkPasswd(v) {
         return v === this.usr.password || 'Password tidak sama';
      },
      // checkUserEmail() {
      //    if(!this.usr.username && !this.usr.email) return 'Username atau email diperlukan';
      //    if(this.usr.username && this.usr.username.length < 3) return 'Username minimal 3 karakter';
      //    if(this.usr.email && !/.+@.+\..+/.test(this.usr.email)) return 'Email harus valid';
      //    return true;
      // },
      close(reload) {
         
         this.$emit('close', reload);
      },
      save() {
         if(!this.$refs.form.validate()) return;
         const {id, ...data} = this.usr;
         this.load_save = true;
         (id === 'new' ? api.create('user', data):
         api.edit('user', this.uid, data)
         ).then(rsl => {
            if(rsl.status !== 1) return this.$alert('Ooops!', rsl.msg, 'error');
            this.$alert({title: 'Berhasil!', text: rsl.msg, type: 'success', timer: 2000 });
            this.close(true);
         }).finally(() => { this.load_save = false });
      },
   }
}
</script>
