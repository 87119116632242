<template>
   <v-app>
      <drawer />
      <toolbar />
      <v-main>
         <v-fade-transition mode="out-in">
            <router-view />
         </v-fade-transition>
      </v-main>
      <alert />
      <order />
      <transaction/>
   </v-app>
</template>

<script>
import Drawer from '../core/Navigation';
import cookies from 'js-cookie'
import CFG from '@/config'
import api from '../../api';
import Alert from '../Alert.vue';
import Toolbar from '../core/Toolbar.vue';
import Transaction from '../Transaction.vue';
import Order from '../Order.vue';

export default {
   name: 'Main',
   components: { Drawer, Alert, Toolbar, Transaction, Order },
   data: () => ({
   }),
   beforeRouteEnter(to, from, next) {
      const token = cookies.get(CFG.tokenKey);
      if(!token) next('/login');
      else next();
   },
   beforeCreate() {
      const {user} = this.$store.state.auth;
      if(!user.id) {
         api.get('user/profile').then(rsl => {
            this.$store.commit('auth/set', rsl.data);
         })
      }
   },
   mounted() {
      // let code = '';
      let timer = 0;
      document.addEventListener("keydown", e => {

         console.log('kd:  ', e.timeStamp - timer);
         timer = e.timeStamp;
         // const textInput = e.key || String.fromCharCode(e.keyCode);
         // const targetName = e.target.localName;
         // let newUPC = '';
         // if (textInput && textInput.length === 1 && targetName !== 'input') {
         //       newUPC = textInput;

         //    if (newUPC.length >= 6) {
         //       console.log('barcode scanned:  ', newUPC);
         //    } 
         // }
      });
   },
};
</script>
<style>
.list-line {display: flex;justify-content: space-between;align-items: center;min-height: 36px;border-bottom: 1px solid #ddd;}
</style>
