import cookies from 'js-cookie'
import CFG from '@/config'
import axios from 'axios'

let sid = cookies.get('x-app');
let token = cookies.get(CFG.tokenKey);
if(!sid) {
   sid = Math.random().toString(36).slice(2);
   cookies.set('x-app', sid);
}
if(token) {
   axios.defaults.headers.common[CFG.tokenHeaderKey] = token;
}

export default {
   namespaced: true,
   state: {sid: sid, user: {}, token: token, loggingIn: !!token},
   actions: {
      ok({commit}, {token, data}) {
         cookies.set(CFG.tokenKey, token, {expires: 1});
         axios.defaults.headers.common[CFG.tokenHeaderKey] = token;
         commit('set', data);
      },
      logout({ commit }) {
         delete axios.defaults.headers.common[CFG.tokenHeaderKey]
         cookies.remove(CFG.tokenKey)
         commit('clear');
      }
   },
   mutations: {
      set(state, {avatar,...user}) {
         state.loggingIn = true;
         if(avatar) user.avatar = CFG.img_url + avatar;
         else user.avatar = '~/assets/avatar.png';
         state.user = user;
      },
      loginFailure(state) {
         state.loggingIn = false;
         state.user = {};
      },
      clear(state) {
         state.loggingIn = false;
         state.user = {};
      },
      setToken() {
         
      }
   }
}
