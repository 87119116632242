<template>
   <v-navigation-drawer v-model="show" app floating clipped width="260" >
      <!-- <v-img
         :src="image"
         height="100%"
      > -->
      <div v-if="$vuetify.breakpoint.mobile" class="my-3 text-center">
         <router-link to="/" class="site-name text-h6">{{name}}</router-link>
         <div class="caption">Pasar Cibinong Blok C3<br/>Cibinong - Bogor</div>
      </div>
      <v-divider class="ma-3" />
      <v-list dense shaped>
         <v-list-item
            v-for="(link, i) in links"
            :key="i"
            :to="link.to"
            active-class="active-menu"
         >
            <v-list-item-action>
               <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title
               v-text="link.text"
            />
         </v-list-item>
      </v-list>
      <!-- </v-img> -->
   </v-navigation-drawer>
   
</template>

<script>
import cfg from '../../config';
export default {
   data: () => ({
      name: cfg.site_name,
      isDark: false,
      links: [
         { to: '/summary', icon: 'mdi-view-dashboard', text: 'Rekap' },
         { to: '/sale', icon: 'mdi-point-of-sale', text: 'Penjualan' },
         { to: '/transaction', icon: 'mdi-cash-multiple', text: 'Transaksi' },
         { to: '/category', icon: 'mdi-shape', text: 'Kategori' },
         { to: '/product', icon: 'mdi-view-list', text: 'Produk' },
         { to: '/customer', icon: 'mdi-account-group', text: 'Pelanggan' },
         { to: '/user', icon: 'mdi-account', text: 'User' }
      ]
   }),
   computed: {
      show: {
         set(val) { this.$store.commit('set', {drawer: val}) },
         get() { return this.$store.state.drawer }
      }
   },
}
</script>

<style lang="scss">
a.site-name { text-decoration: none; }
#app-drawer {
   background-color: transparent;
   .v-list__tile {
      border-radius: 4px;

      &--buy {
         margin-top: auto;
         margin-bottom: 17px;
      }
   }
}
</style>
