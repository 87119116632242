import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
Vue.use(Vuex)

export default new Vuex.Store({
   state: {
      drawer: true,
      alert: [],
      show_cart: false,
      cart: [],
      transaction: {},
      receipe: {}
   },
   mutations: {
      set(state, arr) {
         for(let i in arr) state[i] = arr[i];
      },
      toggleDrawer(state) {
         state.drawer = !state.drawer;
      },
      addAlert(state, data) {
         state.alert.push(data);
      },
      delAlert(state, idx) {
         state.alert.splice(idx, 1);
      },
      addCart(state, data) {
         const idx = state.cart.findIndex(x => x.id === data.id);
         if(idx === -1) state.cart = [...state.cart, data];
         else state.cart[idx].quantity += data.quantity;
      },
      delCart(state, id) {
         state.cart.splice(state.cart.findIndex(x => x.id === id), 1);
      },
      selectAll(state, val) {
         state.cart = state.cart.map(x => ({...x,selected: val}));
      }
   },
   actions: {
   },
   modules: {
      auth,
   }
})
