<template>
   <v-navigation-drawer v-model="show" app floating clipped :width="width" right >
      <v-card-title class="justify-space-between">
         Order
         <v-btn icon color="indigo" @click="show=false"><v-icon>mdi-arrow-right</v-icon></v-btn>
      </v-card-title>
      <v-divider />
      <!-- <div v-if="lock" class="text-center ma-4">KERANJANG KOSONG<br/>tambahkan produk untuk proses transaksi</div> -->
      <v-list subheader two-line >
         <v-list-item v-for="(p,i) in $store.state.cart" :key="i" >
            <v-list-item-avatar>
               <v-img :src="base_img+p.image"/>
            </v-list-item-avatar>
            <v-list-item-content>
               <v-list-item-title>{{p.name}}</v-list-item-title>
               <v-list-item-subtitle class="orange--text" v-idr="p.price"/>
            </v-list-item-content>
            <v-list-item-action>
               <div class="qty-adj">
                  <!-- <v-icon @click="sub(p)" color="grey lighten-1">mdi-minus-circle</v-icon> -->
                  <div>
                     <v-text-field
                        v-model="p.quantity"
                        hide-details
                        dense
                        prepend-icon="mdi-minus-circle"
                        append-outer-icon="mdi-plus-circle"
                        @click:prepend="p.quantity>1 && p.quantity--"
                        @click:append-outer="p.quantity++"
                     />
                  </div>
                  <!-- <v-icon @click="add(p)" color="grey lighten-1">mdi-plus-circle</v-icon> -->
                  <v-icon class="ml-1" @click="del(i)" color="red lighten-1">mdi-delete-outline</v-icon>
               </div>
            </v-list-item-action>
         </v-list-item>
      </v-list>
      <v-card-text>
         <div class="summary">
            <div class="text-center mt-n3">
               <v-icon color="primary" small>mdi-plus-circle</v-icon>
            </div>
            <v-row class="mx-1">
               <v-col cols="3">
                  <v-text-field v-model="quantity" label="Quantity"/>
               </v-col>
               <v-col cols="9">
                  <v-autocomplete
                     :search-input.sync="search_pro"
                     :loading="load_pro"
                     :items="products"
                     v-model="product"
                     item-value="id"
                     item-text="name"
                     return-object
                     label="Produk"
                     placeholder="cari produk..."
                     hide-details
                     @change="addPro"
                  />
               </v-col>
            </v-row>
         </div>
      </v-card-text>
      <v-card-text>
         <div class="summary">
            <div class="ma-3 d-flex justify-space-between">
               <div>Total Item</div>
               <div class="subtitle-2">{{items}}</div>
            </div>
            <div class="ma-3 d-flex justify-space-between">
               <div>Total Harga</div>
               <div class="subtitle-2 blue--text" v-idr:full="total"/>
            </div>
         </div>
      </v-card-text>
      <v-card-actions class="justify-center">
         <v-btn color="primary" width="120" :disabled="lock" outlined rounded @click="proccess">proses</v-btn>
      </v-card-actions>
   </v-navigation-drawer>
</template>

<script>
import config from '../config';
import api from '../api';

export default {
   name: 'Order',
   data: () => ({
      base_img: config.img_url,
      products: [],
      product: {},
      quantity: 1,
      search_pro: null,
      load_pro: false,
   }),
   computed: {
      width() {
         return this.$vuetify.breakpoint.mobile ? 480 : 360;
      },
      show: {
         set(val) { this.$store.commit('set', {show_cart: val}) },
         get() { return this.$store.state.show_cart }
      },
      lock() {
         return !this.$store.state.cart.length;
      },
      items() {
         return this.$store.state.cart.reduce((a, b) => a + parseInt(b.quantity), 0);
      },
      total() {
         return this.$store.state.cart.reduce((a, b) => a + (parseInt(b.price) * parseInt(b.quantity)), 0);
      },
   },
   watch: {
      search_pro(val) {
         clearTimeout(this.timer);
         this.load_pro = true;
         this.timer = setTimeout(() => {
            api.get('product?name='+ val).then(rsl => {
               this.products = rsl.data;
            }).finally(() => { this.load_pro = false })
         })
      }
   },
   methods: {
      addPro(p) {
         this.$store.commit('addCart', {...p, quantity: this.quantity, selected: true});
         this.product = {};
         this.quantity = 1;
      },
      add(p) {
         this.$store.commit('updateCart', {...p, quantity: ++p.quantity});
      },
      sub(p) {
         if(p.quantity < 1) return;
         this.$store.commit('updateCart', {...p, quantity: --p.quantity});
      },
      del(i) {
         this.$store.commit('delCart', i);
      },
      proccess() {
         this.$store.commit('set', {transaction: {id: 'new', product: this.$store.state.cart}});
      }
   }
}
</script>
<style scoped>
.summary {border: 1px solid #ccc; border-radius: 8px;}
.qty-adj > div {width: 110px; display: inline-block;}
.qty-adj > div >>> input {text-align: center;}
</style>
