<template>
   <router-view />
</template>

<script>
export default {
   name: 'App',
};
</script>
<style lang="sass">
@import  '../node_modules/typeface-roboto/index.css'
</style>