import { format } from 'date-fns';

const dater = function (el, bind) {
   const fmt = el.getAttribute('format');
   const date = new Date(bind.value);
   el.innerHTML = bind.value ? format(date, fmt || 'yyyy-MM-dd HH:mm:ss'):'-';
}
const idr = function (el, bind) {
   let opts = {};
   if(bind.arg === 'full') opts = { style: 'currency', currency: 'IDR' };
   el.innerHTML = bind.value === null || bind.value === undefined ? '-' : bind.value.toLocaleString('id-ID', opts);
}

export default {
   install(Vue) {
      Vue.directive('dater', { inserted: dater, update: dater });
      Vue.directive('idr', { inserted: idr, update: idr });
   }
}
