<template>
   <v-text-field :label="label" :hide-details="hideDetails" :dense="dense" v-model="model" />
</template>
<script>
export default {
   props: {label: String, hideDetails: Boolean, dense: Boolean, value: {default:0}},
   data: () => ({

   }),
   computed: {
      model: {
         get() {
            if(isNaN(this.value)) return 0;
            return parseInt(this.value).toLocaleString('id-ID');
         },
         set(val) {
            if(isNaN(val)) this.$emit('input', 0);
            else this.$emit('input', parseInt(val.replace(/\./g,'')));
         }
      }
   },
}
</script>