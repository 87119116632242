<template>
   <v-app-bar app flat clipped-left clipped-right >
      <v-app-bar-nav-icon @click="toggleNavigation" />
      <v-toolbar-title><router-link class="site-name" to="/">{{name}}</router-link></v-toolbar-title>
      <v-spacer/>
      <!-- <v-menu open-on-hover class="toolbar-menu-item" :close-on-content-click="false" nudge-left="10" offset-y origin="center center" transition="scale-transition">
         <template v-slot:activator="{ on, attrs }"> -->
            <v-btn icon @click="toggleCart" ><!-- v-on="on" v-bind="attrs" -->
               <v-badge color="green" overlap>
                  <span slot="badge">{{ $store.state.cart.length }}</span>
                  <v-icon>mdi-cart</v-icon>
               </v-badge>
            </v-btn>
         <!-- </template>
         <cart />
      </v-menu> -->
      <v-menu class="toolbar-menu-item" :close-on-content-click="false" offset-y origin="center center" :nudge-bottom="10" transition="scale-transition">
         <template v-slot:activator="{ on, attrs }">
            <v-btn icon large v-on="on" v-bind="attrs" :ripple="false">
               <v-avatar size="42px">
                  <img :src="$store.state.auth.user.avatar"/>
               </v-avatar>
            </v-btn>
         </template>
         <v-card width="400">
            <v-card-text>
               <v-row dense justify="space-between">
                  <v-col cols="6">
                     <div class="title">{{$store.state.auth.user.fullname}}</div>
                     <div>{{$store.state.auth.user.group_name}}</div>
                     <v-btn class="mt-2" rounded outlined x-small @click="profile_id='profile'" color="green">profile</v-btn>
                  </v-col>
                  <v-col cols="5">
                     <img width="80" :src="$store.state.auth.user.avatar"/>
                  </v-col>
                  <v-col cols="12">
                     <v-divider class="mb-3"/>
                     <div class="subtitle-2">Perference</div>
                     <v-switch v-model="$vuetify.theme.dark" label="Dark mode" />
                     <div class="d-flex justify-space-around mb-3">
                        <v-btn>theme 1</v-btn>
                        <v-btn>theme 2</v-btn>
                        <v-btn>theme 3</v-btn>
                     </div>
                  </v-col>
               </v-row>
               <v-divider />
               <div class="text-center mt-3">
                  <v-btn outlined color="grey" @click="logout">logout</v-btn>
               </div>
            </v-card-text>
         </v-card>
      </v-menu>
      <user-form :uid="profile_id" v-on:close="profile_id=null"/>
   </v-app-bar>
</template>
<script>
import config from '../../config';
import UserForm from '../UserForm.vue';

export default {
   components: { UserForm, },// Cart,
   data: () => ({
      name: config.site_name,
      profile_id: null,
      dialogSettings: false,
      switchEmailNotification: true,
      showPassword: null,
      showPasswordConfirm: null,
      userEmail: null,
      password: null,
      passwordConfirm: null,
      error: false,
      showResult: false,
      result: '',
   }),
   methods: {
      toggleNavigation() {
         this.$store.commit('toggleDrawer');
      },
      toggleCart() {
         this.$store.commit('set', {show_cart:!this.$store.state.show_cart});
      },
      logout() {
         this.$store.dispatch('auth/logout');
         this.$router.push('/login');
      },
   }
};
</script>
<style scoped>
a.site-name {text-decoration: none;}
</style>