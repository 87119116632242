import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/layout/main.vue'

Vue.use(VueRouter)

const routes = [
   { path: '/login', component: () => import('@/views/Login') },
   { 
      path: '/', name: 'Home', component: Layout, redirect: '/sale', children: [
         { path: '/summary', name: 'Rekap', component: () => import(/* webpackChunkName: "summary" */ './views/summary.vue') },
         { path: '/sale', name: 'Sale', component: () => import(/* webpackChunkName: "sale" */ './views/sale.vue') },
         { path: '/customer', name: 'Customers', component: () => import(/* webpackChunkName: "customer" */ './views/customer.vue') },
         { path: '/category', name: 'Kategori', component: () => import(/* webpackChunkName: "category" */ './views/category') },
         { path: '/product', name: 'Produk', component: () => import(/* webpackChunkName: "product" */ './views/product') },
         { path: '/transaction', name: 'Transaksi', component: () => import(/* webpackChunkName: "transaction" */ './views/transaction') },
         { path: '/user', name: 'Users', component: () => import(/* webpackChunkName: "user" */ './views/user') },
      ]
   },
]

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes
})

export default router
